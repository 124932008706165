<template>
    <div id="content" class="settings-user">
        <template v-if="form">
            <Form :id="userID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="addUser" bLabel="user.add"></Button>
            </div>
            <div class="supervise-container user-supervise-container" :class="{ 'with-trial': trialEndDate }">
                <div class="sidebar-filters" :class="{ 'empty-results': noChanges && items.length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                    <FilterUsers
                        :isDisabled="noChanges && items.length == 0"
                        @resetPageDatatable="top(true)"
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                    ></FilterUsers>
                </div>
                <div class="data-table" :class="{ 'empty-results': noChanges && items.length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="items"
                        sort-by="fullname"
                        :sort-asc="true"
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                    >
                        <template #item.identification="item">
                            <div class="id" @click="goToUserConfigForm(item.item.id)">
                                <span class="id">{{ item.item.identification }}</span>
                            </div>
                        </template>
                        <template #item.fullname="item">
                            <div class="created-by" @click="goToUserConfigForm(item.item.id)">
                                <span class="employee-img" v-if="item.item.avatar" :style="{ backgroundImage: 'url(' + item.item.avatar + ')' }"></span>
                                <span class="employee-name" :class="{ pending: item.item.dashboard_access == 2 && item.item.status == 1 }"
                                    >{{ item.item.name }} {{ item.item.surname }}</span
                                >
                            </div>
                        </template>
                        <template #item.rol="item">
                            <div class="title" @click="goToUserConfigForm(item.item.id)">
                                <span v-if="getRole(item.item.rol)">{{ getRole(item.item.rol).name }}</span>
                                <div class="user-rol">
                                    <div v-if="item.item.dashboard_access == 1">
                                        <span class="control-panel-access"></span>
                                        <span>CP</span>
                                    </div>
                                    <div v-if="item.item.pin">
                                        <span class="app-access"></span>
                                        <span>APP</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #item.locations="item">
                            <div class="locations" @click="goToUserConfigForm(item.item.id)">
                                <Tags :tags="Object.values(item.item.locations)" :backImage="'location_inactive_s30.svg'" :nameTag="'name'" value="id"></Tags>
                            </div>
                        </template>
                        <template #item.groups="item">
                            <div class="locations" @click="goToUserConfigForm(item.item.id)">
                                <Tags :tags="Object.values(item.item.groups)" :backImage="'menu_inactive_s30.svg'" :nameTag="'name'" value="id"></Tags>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToUserConfigForm(item.item.id)">
                                <Table-statuses
                                    :text="
                                        item.item.dashboard_access == 2 && item.item.status == '1' && item.item.status == 1
                                            ? $t('user.filters.pending')
                                            : item.item.status == '1'
                                            ? $t('sites.form.active')
                                            : item.item.status == '-2'
                                            ? $t('sites.form.inactive')
                                            : ''
                                    "
                                    :status="
                                        item.item.dashboard_access == '2' && item.item.status == 1 ? 'pending' : item.item.status == '1' ? 'active' : 'inactive'
                                    "
                                >
                                </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && items.length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && items.length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ items.length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && items.length == 0">
                        <EmptyTable
                            @buttonAction="addUser"
                            :buttonText="$t('user.form.save_new_no_access')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.users_desc')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '../components/ui/TableStatuses.vue'
import FilterUsers from '@/components/domain/user/filter'
import Form from '@/components/domain/user/form'
import Tags from '@/components/ui/Tags'
import EmptyTable from '@/components/ui/EmptyTable'
import Button from '@/components/ui/Button.vue'

export default {
    components: {
        FilterUsers,
        Form,
        Tags,
        EmptyTable,
        TableStatuses,
        Button
    },
    name: 'Users',
    data() {
        return {
            noChanges: false,
            userID: false,
            form: false,
            values: false,
            singleSelect: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            valueFilter: 1,
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('user.id'),
                    value: 'identification',
                    align: 'start',
                    width: '100px',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('user.user_name_surname'),
                    align: 'start',
                    sortable: true,
                    value: 'fullname',
                    width: '400px',
                    class: 'header-table'
                },
                {
                    text: this.$t('user.role'),
                    value: 'rol',
                    width: '100px',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('user.sites'),
                    value: 'locations',
                    align: 'start',
                    width: '200px',
                    sortable: true,
                    sort: this.sortColumnLocation,
                    class: 'header-table'
                },
                {
                    text: this.$t('user.filters.territories'),
                    value: 'groups',
                    align: 'start',
                    width: '200px',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('product.status'),
                    value: 'status',
                    align: 'start',
                    width: '200px',
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        hasAccess() {
            return this.checkPermission('/users/add')
        },
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            var items = Object.values(this.$store.getters['employee/getAllUsers'])
            return items
        },
        itemsLoaded() {
            return this.$store.getters['employee/getItemsLoaded']
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        canCreateUsers() {
            return this.$store.getters.getCanCreateUsers
        }
    },
    methods: {
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        sortColumnLocation(a, b) {
            var Aname = Object.values(a).length > 0 ? Object.values(a)[0].name : ''
            var Bname = Object.values(b).length > 0 ? Object.values(b)[0].name : ''
            if (Aname < Bname) {
                return -1
            }
            return 1
        },

        getLoggedUserRoleLevel() {
            const loggedUser = this.$store.getters['login/getLocalEmployee']
            const loggedUserRole = this.$store.getters['employee/getRoles'](loggedUser.rol)
            const loggedUserRoleLevel = Number(loggedUserRole.level)

            return loggedUserRoleLevel
        },

        getRoleLevelOfUserToEdit(id) {
            const user = this.items.filter((user) => {
                return user.id == id
            })[0]

            const roleInfo = this.$store.getters['employee/getRoles'](user.rol)
            const roleLevel = roleInfo.level

            return roleLevel
        },

        goToUserConfigForm(id) {
            if (this.checkPermission('/users/idUser')) {
                let canGoToUserConfigForm = true

                if (!this.canCreateUsers) {
                    canGoToUserConfigForm = false

                    const loggedUserRoleLevel = this.getLoggedUserRoleLevel()

                    if (loggedUserRoleLevel >= 4) {
                        const roleLevelOfUserToEdit = this.getRoleLevelOfUserToEdit(id)

                        if (roleLevelOfUserToEdit <= 3) {
                            canGoToUserConfigForm = true
                        }
                    }
                }

                if (canGoToUserConfigForm) {
                    this.form = true
                    this.userID = id
                } else {
                    var self = this

                    self.$snackbar.open({
                        message: self.$t('user.form.no_permission_edit'),
                        duration: 5000
                    })
                }
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('user.form.no_permission'),
                    duration: 5000
                })
            }
        },

        closeForm() {
            this.form = false
            this.userID = false
            this.$router.push({
                name: 'Users',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        addUser() {
            if (this.checkPermission('/users/add') && this.canCreateUsers) {
                this.form = true
                this.userID = false
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('user.form.no_permission'),
                    duration: 5000
                })
            }
        },
        getRole(id) {
            return this.$store.getters['employee/getFilterRoles'](id)
        },
        load() {
            var self = this
            self.$overlay.loading()
            var items = this.$store.getters['employee/getAllUsers']
            var filterRoles = this.$store.getters['employee/getFilterRoles']()
            var filterSites = this.$store.getters['employee/getFilterSites']()
            var filterTerritories = this.$store.getters['employee/getFilterGroups']()
            var groups = this.$store.getters['groups/getList']()
            var loadFilters = this.$store.getters['employee/getHasLoadFilters']

            if (
                Object.keys(filterRoles).length > 0 &&
                Object.keys(filterSites).length > 0 &&
                Object.keys(filterTerritories).length > 0 &&
                Object.keys(groups).length > 0 &&
                !loadFilters
            ) {
                if (self.$route.params.id) {
                    self.goToUserConfigForm(self.$route.params.id)
                } else if (self.$route.name == 'AddUser') {
                    self.addUser()
                } else {
                    self.form = false
                }
                self.$overlay.hide()
            } else {
                self.$store.dispatch('groups/loadList', {}).then(function () {
                    self.$overlay.hide()
                    self.$store.commit('employee/setHasLoadFilters', false)
                    if (self.$route.params.id) {
                        self.goToUserConfigForm(self.$route.params.id)
                    } else if (self.$route.name == 'AddUser') {
                        self.addUser()
                    } else {
                        self.form = false
                    }
                })
            }
        },

        formatTime(value) {
            return moment(value).format('DD/MM/YYYY HH:mm') != moment('00/00/0000 00:00').format('DD/MM/YYYY HH:mm')
                ? moment(value).format('DD/MM/YYYY HH:mm')
                : '-'
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('settingsUsersFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('settingsUsersFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            this.load()
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.settings-user {
    .user-supervise-container {
        height: calc(100% - 100px);
    }
}
</style>

<style lang="scss" scoped>
.user-rol {
    @include display-flex;
    @include flex-direction(row);
    @include justify-content(flex-start);
    @include align-items(center);
    gap: 10px;

    div {
        @include display-flex;
        @include flex-direction(row);
        @include justify-content(flex-start);
        @include align-items(center);
        gap: 5px;

        span {
            @include font-size(xs);
        }

        .control-panel-access,
        .app-access {
            width: 10px;
            height: 10px;
            @include border-radius(50%);
        }

        .control-panel-access {
            background-color: $color-secondary-500;
        }

        .app-access {
            background-color: $color-primary-500;
        }
    }
}
</style>
