var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar-container"},[_c('div',{staticClass:"avatar-selected"},_vm._l((_vm.images),function(image){return (
        image.pathShort.substring(2) ==
          _vm.avatarSelected.split('/')[_vm.avatarSelected.split('/').length - 1] ||
          image.pathShort.slice(2) == _vm.avatarSelected
      )?_c('img',{key:image.pathLong,staticClass:"profile",attrs:{"src":image.pathLong,"alt":"user-profile-photo"}}):_vm._e()}),0),_c('div',{staticClass:"all-avatars"},_vm._l((_vm.images),function(image){return _c('div',{key:image.pathLong,staticClass:"avatar-img",class:{
        active: image.pathShort.slice(2) == _vm.avatarSelected,
        active:
          image.pathShort.substring(2) ==
          _vm.avatarSelected.split('/')[_vm.avatarSelected.split('/').length - 1]
      },style:({ backgroundImage: 'url(' + image.pathLong + ')' }),on:{"click":function($event){return _vm.setAvatar(image)}}})}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }