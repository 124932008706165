<template>
    <div v-if="employee" id="content" class="user-management">
        <div class="bar-actions">
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel" />
            <Button
                bType="save"
                :bCallback="saveUser"
                :bLabel="$route.params.id ? 'user.form.save' : role3 ? 'user.form.save_new' : 'user.form.save_new_no_access'"
                :customClass="{
                    disabled: !id_new || !email_new,
                    'btn-action': id_new && email_new
                }"
            />
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions" />
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showConfirmDeleteUserModal" bLabel="menu_aux.action.delete" customClass="btn-secondary" />
            </div>
        </div>

        <div class="form-container">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container" :class="{ disabled: !id_new || !email_new || !canCreateUsers }">
                    <div class="left-container">
                        <!-- <h2 class="label-form">{{ $t('user.user_name') }}</h2> -->
                        <!-- <p v-if="emptyAnswer.name" class="error-form error-field" v-html="$t('user.form.error.name')"></p> -->
                        <!-- INPUT NAME -->
                        <Input
                            class="name-section"
                            v-model="user.name"
                            :gOptions="{ mandatory: true }"
                            :gPlaceholder="$t('user.form.name_placeholder')"
                            :gLabel="$t('user.user_name')"
                            :gMessage="{ error: $t('user.form.error.name') }"
                            :gState="emptyAnswer.name ? 'error' : ''"
                            gSize="m"
                        />
                        <!-- <input :class="{ disabled: !id_new }" type="text" autocomplete="off" v-model="user.name" :placeholder="$t('user.form.name_placeholder')" class="input w85" /> -->
                    </div>
                    <div class="right-container" :class="{ disabled: !id_new || !email_new }">
                        <!-- <h2 class="label-form">{{ $t('user.form.surname') }}</h2> -->
                        <!-- <p v-if="emptyAnswer.surname" class="error-form error-field" v-html="$t('user.form.error.surname')"></p> -->
                        <div class="user-answer">
                            <!-- INPUT SURNAME -->
                            <Input
                                v-model="user.surname"
                                :gPlaceholder="$t('user.form.surname_placeholder')"
                                :gLabel="$t('user.form.surname')"
                                :gMessage="{ error: $t('user.form.error.surname') }"
                                :gState="emptyAnswer.surname ? 'error' : ''"
                                gSize="m"
                            />
                            <!-- <input type="text" autocomplete="off" v-model="user.surname" class="input w85" :placeholder="$t('user.form.surname_placeholder')" name="surname" :class="{ disabled: !id_new }" id="surname" /> -->
                        </div>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container" :class="{ disabled: !id_new || !email_new || !canCreateUsers }">
                        <!-- AVATAR -->
                        <h2 class="label-form">{{ $t('user.form.avatar') }}</h2>
                        <p class="description">{{ $t('user.form.avatar_desc') }}</p>

                        <Avatar :avatarSelected="user.avatar" @setImage="setImage" />
                    </div>
                    <div class="right-container" :class="{ disabled: !id_new || !email_new }">
                        <!-- SWITCH ACTIVE/INACTIVE -->
                        <div class="switch">
                            <h2 class="label-form" v-html="statusUser ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="active-switch" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="statusUser" />
                            </div>
                        </div>
                        <p class="description">{{ $t('user.form.info.switch_desc') }}</p>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container" :class="{ disabled: !email_new || !canCreateUsers }">
                        <!-- <h2 class="label-form">{{ $t('user.id') }}</h2> -->
                        <!-- <p v-if="id_new" class="description" v-html="$t('user.form.id_descrip')"></p> -->
                        <!-- <p v-else class="identification" v-html="$t('user.form.id_used')"></p> -->
                        <!-- <p v-if="emptyAnswer.id" class="error-form error-field" v-html="$t('user.form.error.id')"></p> -->
                        <div class="user-answer">
                            <!-- INPUT IDENTIFICATION / IDENTIFICADOR -->
                            <Input
                                class="id-input"
                                @blur="searchID()"
                                v-model="user.identification"
                                :gHelp="$t('user.form.id_descrip')"
                                :gLabel="$t('user.id')"
                                :gMessage="{ error: $t('user.form.id_used') }"
                                :gState="!id_new ? 'error' : ''"
                                gSize="m"
                            />
                            <!-- <input type="text" autocomplete="off" @blur="searchID()" name="id" id="id" v-model="user.identification" class="input" /> -->
                        </div>
                    </div>
                    <div class="right-container" :class="{ disabled: !id_new || !canCreateUsers }">
                        <!-- <h2 class="label-form">{{ $t('user.form.email') }}</h2> -->
                        <!-- <p class="description">{{ $t('user.form.email_descrip') }}</p> -->
                        <!-- <p v-if="!email_new" class="error-form error-field" v-html="$t('user.form.error.email_used')"></p> -->
                        <!-- <p v-if="emptyAnswer.email" class="error-form error-field" v-html="$t('user.form.error.email')"></p> -->
                        <!-- <p v-if="validEmail" class="error-form error-field spaced" v-html="$t('user.form.error.not_email')"></p> -->
                        <div class="user-answer">
                            <!-- TODO VER COMO FUNCIONAN LOS ERRORES -->
                            <Input
                                @blur="emailCheck()"
                                :gPlaceholder="$t('user.form.email_placeholder')"
                                v-model="emailForm"
                                :gHelp="$t('user.form.email_descrip')"
                                :gLabel="$t('user.form.email')"
                                :gMessage="{
                                    default: '',
                                    error: $t('user.form.error.email_used'),
                                    error2: $t('user.form.error.email'),
                                    error3: $t('user.form.error.not_email')
                                }"
                                :gState="!email_new ? 'error' : emptyAnswer.email ? 'error2' : validEmail ? 'error3' : ''"
                                gSize="m"
                            />
                            <!-- <input @blur="emailCheck()" type="text" autocomplete="off" v-model="emailForm" class="input w85" name="email" :class="{ disabled: !id_new }" id="email" /> -->
                        </div>
                    </div>
                </div>
                <!-- CONFIG ROLE -->
                <h1 class="form-title noMarginBottom" :class="{ disabled: !id_new || !email_new || (!canCreateUsers && loggedUserRoleLevel <= 3) }">
                    {{ $t('user.form.user_rol') }}
                </h1>
                <div class="label-container roles" :class="{ disabled: !id_new || !email_new || (!canCreateUsers && loggedUserRoleLevel <= 3) }">
                    <p class="description w100">{{ $t('user.form.user_rol_descrip') }}</p>

                    <p v-if="emptyAnswer.rol" class="error-form error-field" v-html="$t('user.form.error.rol')"></p>
                    <div v-if="roleLoggedUser.employee_id == user.id" class="info-box same-user">
                        {{ $t('user.form.info.same_user') }}
                    </div>
                    <div class="has-role">
                        <div class="box-container" :class="{ hasRole: user.rol }">
                            <div
                                class="no-role"
                                v-if="
                                    (checkRoleIsValid(role.id) || (allRolesList && user.rol == role.id)) &&
                                    ((user.rol == '' && createUserLowerLevel(role.level)) || (allRolesList && createUserLowerLevel(role.level)))
                                "
                                v-for="role in sortedByLevel(optionsRoles)"
                                :key="role.id"
                                @click="selectNewRole(role)"
                            >
                                <div class="box-info">
                                    <div
                                        class="select-role"
                                        :class="{
                                            selected: role.id == newRole || role.id == user.rol
                                        }"
                                    ></div>
                                    <div class="box-name">{{ role.name }}</div>
                                    <div class="default-name">({{ $t(role.default_name) }}, {{ $t('user.form.level') }}{{ ' ' + role.level }})</div>
                                </div>
                                <div class="box-description">
                                    {{ $t('user.form.roles.' + role.level) }}
                                </div>
                            </div>

                            <div class="have-description" v-else-if="role.id == user.rol && !allRolesList">
                                <div class="box-info">
                                    <div class="select-role selected"></div>
                                    <div class="box-name">{{ role.name }}</div>
                                    <div class="default-name">({{ $t(role.default_name) }}, {{ $t('user.form.level') }} {{ ' ' + role.level }})</div>
                                </div>
                                <div class="box-description">
                                    {{ $t('user.form.roles.' + role.level) }}
                                </div>
                            </div>
                        </div>
                        <Button
                            v-if="!allRolesList && user.rol != ''"
                            bType="edit"
                            :bCallback="changeRole"
                            bLabel="user.form.change_role"
                            customClass="btn-secondary"
                            :class="{ disabled: roleLoggedUser.employee_id == user.id }"
                        />
                    </div>
                </div>
                <div class="label-container spaced" :class="{ disabled: !id_new || !canCreateUsers }">
                    <div class="left-container" :class="{ disabled: !email_new }" v-if="canAccessApp()">
                        <div class="up">
                            <h1 class="form-title dashboard-access">{{ $t('user.form.alex_app_access') }}</h1>
                            <!-- SWITCH HERE PLS -->

                            <div class="switch access">
                                <div class="switch-click" @click="popupAlex()">
                                    <input class="switch-input-form access" type="checkbox" v-model="user.pin" disabled="true" />
                                </div>
                            </div>
                        </div>
                        <div class="access-container" v-if="user.pin || hasPin">
                            <h2 class="label-form">{{ $t('user.form.pin_key') }}</h2>
                            <p class="description" v-html="$t('user.form.pin_key_description')"></p>

                            <p v-if="emptyAnswer.pin" class="error-form error-field" v-html="$t('user.form.error.pin')"></p>
                            <p v-if="emptyAnswer.pin2" class="error-form error-field" v-html="$t('user.form.error.pin2')"></p>
                            <div class="temporal_pin" v-if="newPin !== false || user.rol === ''">
                                <div class="info-box">
                                    {{ $t('user.form.temporal_pin_desc') }}
                                </div>
                                <input
                                    type="number"
                                    autocomplete="off"
                                    class="input"
                                    max="9999"
                                    inputmode="numeric"
                                    name="pin"
                                    v-model="newPin"
                                    :class="{ disabled: !id_new }"
                                    @input="onlyNumbers"
                                    @change="onlyNumbers"
                                    id="pin"
                                    :placeholder="$t('user.form.pin_key_placeholder')"
                                />
                                <!-- <Input v-model="newPin" :gLabel="$t('user.user_name')" maxlength ="4" gSize="m"></Input> -->
                                <Button bType="refresh" :bCallback="popupSetPinRandom" bLabel="user.form.reset_pin" customClass="btn-secondary" />
                            </div>
                            <div class="user-answer" v-else>
                                <Button class="setPin" :bCallback="popupSetPin" bType="edit" bLabel="user.form.modify_pin" customClass="btn-secondary" />
                            </div>
                        </div>
                        <div class="access-container" v-else>
                            {{ $t('user.form.app_no_access') }}
                        </div>
                    </div>
                    <div class="right-container" v-if="canAccessDashboard()">
                        <div class="up">
                            <h1 class="form-title dashboard-access">{{ $t('user.form.dashboard_access') }}</h1>
                            <!-- SWITCH HERE PLS -->
                            <div class="switch access">
                                <div class="switch-click" @click="popupDashboard()">
                                    <input class="switch-input-form access" type="checkbox" v-model="switchDashbord" disabled="true" />
                                </div>
                            </div>
                        </div>
                        <!-- Ha cambiado el correo y ya tenía acceso -->
                        <div
                            class="warning-email"
                            v-if="emailChanged && user.dashboard_access && user.email != null"
                            v-html="$t('user.form.changing_email', { name: user.name, surname: user.surname })"
                        ></div>
                        <!-- Si el usuario está inactivo -->
                        <div
                            class="info-box warning"
                            v-else-if="!statusUser && user.status == -2"
                            v-html="$t('user.form.status_inactive', { name: user.name, surname: user.surname })"
                        ></div>
                        <!-- Si el usuario está activo y se inactiva -->
                        <div
                            class="info-box warning"
                            v-else-if="!statusUser && user.status != -2"
                            v-html="$t('user.form.changing_status_user', { name: user.name, surname: user.surname })"
                        ></div>
                        <!-- Usuario pendiente de invitación -->
                        <div class="access-container" v-else-if="user.dashboard_access == 2 && !emailChanged">
                            <div class="info-box warning">
                                <span class="bold-warning" v-html="$t('user.form.info.email_pending', { name: user.name + ' ' + user.surname + ' ' })"></span>
                            </div>
                            <div class="user-answer">
                                <Button
                                    v-if="user.dashboard_access == 2"
                                    bType="send"
                                    :bCallback="sendInvitation"
                                    :bCallbackProps="{ resend: true, id: user.id }"
                                    bLabel="user.form.send_invitation"
                                    :customClass="['btn-clear user-invitation', { disabled: !id_new }]"
                                />
                            </div>
                            <div class="user-answer link">
                                <p class="description link">
                                    {{ $t('user.form.link_description') }}
                                </p>
                                <div class="copy-box">
                                    <input readonly id="copyShareLink" class="link-copy" width="100%" :value="user.invitation_link" />
                                    <Button :bCallback="copyText" customClass="btn-link-copy" bLabel="user.form.copy_link" />
                                </div>
                            </div>
                        </div>
                        <!-- Nuevo correo -->
                        <div v-else-if="changeToNewEmail">
                            <div class="info-box" v-html="$t('user.form.new_email', { email: emailForm })"></div>
                        </div>
                        <!-- Tiene acceso a dashboard  -->
                        <div v-else-if="user.email && user.dashboard_access == 1">
                            <Button
                                :bCallback="requestSendPassword"
                                bLabel="user.form.request_change_password"
                                :customClass="['btn-password-change', { disabled: !id_new }]"
                            />
                        </div>
                        <!-- No tiene acceso a dashboard -->
                        <div class="no-access" v-else v-html="$t('user.form.dash_no_access', { name: user.name + ' ' + user.surname })"></div>
                    </div>
                </div>
                <div class="territory" v-if="user && user.rol != ''" :class="{ disabled: !canCreateUsers }">
                    <h1 class="form-title">{{ $t('user.form.territory') }}</h1>
                    <p v-if="emptyAnswer.locations" class="error-form error-field" v-html="$t('user.form.error.locations')"></p>
                    <div class="label-container" :class="{ disabled: !id_new || !email_new }" v-if="globalAccess()">
                        <div class="left-container" id="user-info">
                            <div class="column-description">
                                <div class="box-container">
                                    <div class="column-description" @click="changeUbication('locations')">
                                        <div class="box-info">
                                            <div class="select-role" :class="{ selected: sites == 'locations' }"></div>
                                            <div class="box-name">{{ $t('user.form.localization') }}</div>
                                        </div>
                                        <div class="box-description">
                                            {{ $t('user.form.localization_desc') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="box-container" v-if="canSeeGroups()">
                                    <div class="column-description" @click="changeUbication('groups')">
                                        <div class="box-info">
                                            <div class="select-role" :class="{ selected: sites == 'groups' }"></div>
                                            <div class="box-name">{{ $t('user.form.group') }}</div>
                                        </div>
                                        <div class="box-description">
                                            {{ $t('user.form.group_desc') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-container">
                            <Input
                                v-if="sites == 'locations'"
                                :key="refreshInput"
                                :gLabel="$t('user.form.where_access')"
                                gType="autocomplete"
                                v-model="user.locations"
                                :autocompleteValue="user.locations"
                                :gOptions="{
                                    multiple: true,
                                    returnObject: true,
                                    removable: true,
                                    mandatory: true,
                                    itemText: 'name',
                                    itemValue: 'id'
                                }"
                                :gItems="optionsSites"
                            />

                            <Input
                                v-else
                                :key="refreshInput"
                                :gLabel="$t('user.form.where_access')"
                                gType="autocomplete"
                                v-model="user.groups"
                                :autocompleteValue="user.groups"
                                :gOptions="{
                                    multiple: true,
                                    returnObject: true,
                                    removable: true,
                                    mandatory: true,
                                    itemText: 'name',
                                    itemValue: 'id'
                                }"
                                :placeholder="$t('user.form.search_groups')"
                                :gItems="optionsGroups"
                            />
                        </div>
                    </div>
                    <div class="info-box access" v-else>
                        {{ $t('user.form.info.global_access') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import Avatar from '@/components/domain/user/avatarSelector'

export default {
    name: 'UserManagement',
    props: { id: undefined },
    components: { Tags, Avatar },
    data() {
        return {
            refreshInput: 0,
            emailReg:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allRolesList: false,
            emailForm: '',
            emailChanged: false,
            changeToNewEmail: false,
            switchDashboardApi: '',
            statusUser: '1',
            user: {
                avatar: '',
                rol: '',
                sites: '',
                locations: [],
                groups: [],
                name: '',
                surname: '',
                pin: '',
                identification: '',
                email: '',
                password: '',
                phone: '',
                id: '',
                status: '',
                dashboard_access: 0,
                invitation_link: false
            },
            sendForm: true,
            id_new: true,
            email_new: true,
            sites: 'locations',
            emptyAnswer: {
                name: false,
                surname: false,
                id: false,
                rol: false,
                email: false,
                pin: false,
                pin2: false,
                notSameEmail: false,
                locations: false
            },
            validEmail: false,
            hasPin: false,
            newPin: false,
            role3: '',
            pinChanged: false,
            images: [],
            newRole: '',
            switchDashbord: false,
            moreOptions: false
        }
    },
    computed: {
        roleLoggedUser() {
            return this.$store.getters['login/getLocalEmployee']
        },
        employee() {
            if (this.id) {
                var user = { ...this.$store.getters['employee/getEmployee'](this.id) }
                if (user.id) {
                    // LOGICA SELECTS LOCATIONS
                    if (user.locations) {
                        if (Object.values(user.locations).length > 0) {
                            this.sites = 'locations'
                        }
                    }
                    if (user.groups) {
                        if (Object.values(user.groups).length > 0) {
                            this.sites = 'groups'
                        }
                    }
                    if (user.pin == 'false') {
                        user.pin = false
                    }
                    if (user.status == 1) {
                        this.statusUser = true
                    } else {
                        this.statusUser = false
                    }
                    if (user.password == 'false') {
                        user.password = false
                    }
                    if (user.dashboard_access == 1 || user.dashboard_access == 2) {
                        this.switchDashbord = true
                        this.switchDashboardApi = JSON.parse(JSON.stringify(user.dashboard_access))
                    }

                    if (user.locations) {
                        user.locations = Object.values(user.locations)
                    }
                    if (user.groups) {
                        user.groups = Object.values(user.groups)
                    }
                    this.user = user
                    this.emailForm = JSON.parse(JSON.stringify(user.email))
                    return true
                } else {
                    this.user = false
                    return false
                }
            } else {
                return true
            }
        },

        allRoles: function () {
            let roles = _.orderBy(this.optionsRoles, 'level')

            if (!this.canCreateUsers) {
                let filteredRoles = {}

                for (var k in roles) {
                    if (parseInt(roles[k].level) <= 3) {
                        filteredRoles[k] = { ...roles[k] }
                    }
                }

                return filteredRoles
            }
            return roles
        },
        loggedUser() {
            var userLoggedId = this.$store.getters['login/getLocalEmployee'].employee_id
            var loggedUser = this.$store.getters['employee/getAccountEmployee'](userLoggedId)
            return loggedUser
        },
        optionsLocations() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                { value: 'groups', name: i18n.t('user.filters.territories') }
            ]
        },
        optionsSites() {
            let sites = Object.values(this.$store.getters['employee/getFilterSites']())
            // sites = sites.filter((site) => parseInt(site.status) === 1 && !site.demo)
            return sites
        },
        optionsRoles() {
            return this.$store.getters['employee/getRoles']()
        },
        optionsGroups() {
            let groups = Object.values(this.$store.getters['employee/getFilterGroups']())
            groups = groups.filter((group) => parseInt(group.status) === 1)
            return groups
        },
        canCreateUsers() {
            return this.$store.getters.getCanCreateUsers
        },
        loggedUserRoleLevel() {
            const loggedUser = this.$store.getters['login/getLocalEmployee']
            const loggedUserRole = this.$store.getters['employee/getRoles'](loggedUser.rol)
            const loggedUserRoleLevel = Number(loggedUserRole.level)

            return loggedUserRoleLevel
        }
    },
    methods: {
        changeUbication(ubication) {
            this.sites = ubication
            this.refreshInput++
        },
        isEmailValid() {
            this.emailForm == '' || this.emailForm == null
                ? (this.validEmail = false)
                : this.emailReg.test(this.emailForm)
                ? (this.validEmail = false)
                : (this.validEmail = true)
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        copyText() {
            /* Get the text field */
            var copyText = document.getElementById('copyShareLink')

            /* Select the text field */
            copyText.select()
            copyText.setSelectionRange(0, 99999) /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand('copy')

            /* Alert the copied text */
            this.$snackbar.open({
                message: this.$t('snackbar.copy_success') + ' <b>' + copyText.value + '</b>',
                duration: 3000
            })
        },
        setImage(img) {
            this.user.avatar = img
        },
        popupStatus() {
            var selfThis = this
            if (selfThis.statusUser == '1' || selfThis.statusUser == true) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('user.form.info.inactive_switch'),
                    textSave: selfThis.$t('user.form.info.inactive_confirm'),
                    callSave: function () {
                        selfThis.statusUser = false
                    },
                    callCancel: function () {
                        selfThis.statusUser = true
                    }
                })
            } else {
                selfThis.statusUser = true
            }
        },
        canSeeGroups() {
            if (this.optionsGroups.length == 0) {
                return false
            } else {
                if (this.optionsGroups.length > 0) {
                    return true
                }
            }
        },
        globalAccess() {
            if (this.optionsRoles) {
                if (this.employee && this.user.rol != '') {
                    if (this.optionsRoles[this.user.rol] && this.optionsRoles[this.user.rol].level >= 6) {
                        return false
                    } else {
                        return true
                    }
                }
            }
        },
        popupDashboard() {
            var selfThis = this
            if (selfThis.switchDashbord) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('user.form.info.dashboard_inactive'),
                    textSave: selfThis.$t('user.form.info.dashboard_confirm'),
                    callSave: function () {
                        selfThis.user.password = false
                        selfThis.user.dashboard_access = 0
                        selfThis.changeToNewEmail = false
                        selfThis.switchDashbord = false
                        selfThis.emailEmpty()
                    },
                    callCancel: function () {
                        selfThis.user.dashboard_access = 1
                        selfThis.emailEmpty()
                    }
                })
            } else {
                if (this.switchDashboardApi != '') {
                    if (this.switchDashboardApi == 1) {
                        selfThis.changeToNewEmail = false
                    }
                    selfThis.user.dashboard_access = this.switchDashboardApi
                } else {
                    selfThis.changeToNewEmail = true
                    selfThis.user.dashboard_access = 1
                }
                selfThis.user.password = true
                selfThis.switchDashbord = true
            }
        },
        popupAlex() {
            var selfThis = this
            if (selfThis.user.pin != '' && selfThis.user.pin != null) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('user.form.info.alex_inactive'),
                    textSave: selfThis.$t('user.form.info.alex_confirm'),
                    callSave: function () {
                        selfThis.user.pin = false
                        selfThis.hasPin = false
                        selfThis.pinChanged = false
                    },
                    callCancel: function () {}
                })
            } else {
                selfThis.newPin = true
                selfThis.pinChanged = true
                selfThis.hasPin = true
                this.user.pin = '1111'
                this.newPin = ''
            }
        },
        emailEmpty() {
            if ((this.user.dashboard_access && this.emailForm == '') || (this.user.dashboard_access && this.emailForm == null)) {
                this.emptyAnswer.email = true
            } else {
                this.emptyAnswer.email = false
            }
        },
        requestSendPassword() {
            var self = this
            self.$overlay.show()
            self.$store
                .dispatch('employee/requestResetPass', {
                    id: this.user.id,
                    lang: this.roleLoggedUser.lang.slice(0, -3)
                })
                .then(function (response) {
                    self.$overlay.hide()
                    self.$snackbar.open({
                        message: self.$t('snackbar.invitation_success', { email: self.emailForm }),
                        customClass: 'success',
                        duration: 5000
                    })
                    // self.$emit('back')
                })
        },
        selectNewRole(newRole) {
            this.user.rol = newRole.id
            this.allRolesList = false
            const rolLevel = parseInt(newRole.level)
            if (rolLevel <= 3 && (this.id === false || this.user.pin === '' || this.user.pin === false)) {
                this.statusUser = true
                this.newPin = true
                this.pinChanged = true
                this.hasPin = true
                this.user.pin = '1234'
                this.newPin = '1234'
            }
        },
        canAccessApp() {
            if (this.optionsRoles) {
                if (this.optionsRoles[this.user.rol]) {
                    var levelCurrentUser = this.optionsRoles[this.user.rol].level
                    if (levelCurrentUser >= 1 || levelCurrentUser >= 8) {
                        return true
                    }
                }
            }
        },
        canAccessDashboard() {
            if (this.optionsRoles) {
                if (this.optionsRoles[this.user.rol]) {
                    var levelCurrentUser = this.optionsRoles[this.user.rol].level
                    if (levelCurrentUser >= 3 && levelCurrentUser <= 9) {
                        return true
                    } else {
                    }
                }
            }
        },
        createUserLowerLevel(level) {
            var role = this.$store.getters['employee/getRoles'](this.roleLoggedUser.rol)
            if (role) {
                if (role.level > level) {
                    return true
                }
                return false
            }
        },
        setAvatar(image) {
            var img = image.pathShort.slice(2)
            this.user.avatar = img
        },
        changeRole() {
            this.allRolesList = true
        },
        importAll(r) {
            r.keys().forEach((key) => this.images.push({ pathLong: r(key), pathShort: key }))
        },
        popupSetPinRandom() {
            var val = Math.floor(1000 + Math.random() * 9000)
            this.newPin = val.toString()
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.

            if (val.length > 4) {
                this.newPin = val.substring(0, 4)
            }

            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },
        setPin() {
            this.newPin = ''
            this.hasPin = true
        },
        popupSetPin() {
            var self = this

            self.$popup.confirm({
                message: self.$t('user.form.info.reset_pin'),
                textSave: self.$t('user.form.info.reset_pin_save'),
                callSave: function () {
                    self.setPin()
                }
            })
        },
        load() {
            if (this.$route.name == 'Users') {
                if (this.id !== false) {
                    this.$router.push({ name: 'EditUser', params: { id: this.id } })
                } else {
                    this.$router.push({ name: 'AddUser' })
                }
            }

            if (this.optionsSites && this.optionsSites.length === 1) {
                this.user.locations.push(this.optionsSites[0])
            }
        },
        refreshSearch() {
            this.user.locations = []
            this.user.groups = []
        },
        searchID() {
            var self = this
            var params = {
                identification: this.user.identification,
                employee_id: this.user.id
            }
            if (!this.user.identification == '') {
                this.$store.dispatch('employee/identificationUsed', params).then(function (response) {
                    self.id_new = response
                })
            }
        },
        emailCheck() {
            this.isEmailValid()
            var self = this
            var params = { email: this.emailForm, id: this.user.id }
            if (!this.emailForm == '') {
                this.$store.dispatch('employee/emailCheck', params).then(function (response) {
                    self.email_new = response
                    return response
                })
            }
            this.emptyAnswer.email = false
            return true
        },
        sendInvitation(onlyResend) {
            var self = this

            return new Promise((resolve, reject) => {
                self.$overlay.show()
                self.$store
                    .dispatch('employee/sendInvitation', {
                        id: onlyResend.id,
                        lang: self.roleLoggedUser.lang.slice(0, -3)
                    })
                    .then(function (response) {
                        self.$overlay.hide()
                        if (onlyResend.resend) {
                            if (response.error) {
                                var responses = response.error
                                if (responses.code == -2) {
                                    self.$snackbar.open({
                                        message: self.$t('user.form.already_access_dashboard', {
                                            name: self.user.name + ' ' + self.user.surname
                                        }),
                                        duration: 5000
                                    })
                                }
                            } else {
                                self.$snackbar.open({
                                    message: self.$t('user.form.resend_invitation', { email: self.emailForm }),
                                    customClass: 'success',
                                    duration: 5000
                                })
                            }
                        } else {
                            if (response.error) {
                                var responses = response.error
                                if (responses.code == -2) {
                                    self.$snackbar.open({
                                        message: self.$t('user.form.already_access_dashboard', {
                                            name: self.user.name + ' ' + self.user.surname
                                        }),
                                        duration: 5000
                                    })
                                }
                            } else {
                                self.$snackbar.open({
                                    message: self.$t('snackbar.invitation_dashboard_success', { email: self.emailForm }),
                                    customClass: 'success',
                                    duration: 5000
                                })
                            }
                        }

                        resolve()
                    })
                    .catch((err) => {
                        reject()
                    })
            })
        },
        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        role() {
            if (this.optionsRoles) {
                var role = this.optionsRoles[this.user.rol]
                if (role) {
                    if (role.level >= 3) {
                        this.role3 = true
                    } else {
                        this.role3 = false
                    }
                }
            }
        },
        sendFormUser() {
            var self = this
            self.$overlay.loading()
            if (this.user.avatar.includes('@') || this.user.avatar.includes('http')) {
                this.user.avatar = this.user.avatar.split('/')[this.user.avatar.split('/').length - 1]
            }
            if (this.pinChanged) {
                this.user.pin = sha256(this.user.pin)
            }
            if (!this.canAccessDashboard()) {
                this.user.dashboard_access = 0
            }
            if (!this.canAccessApp()) {
                this.user.pin = null
            }

            if (this.user.groups) {
                this.user.groups
                var group = []
                for (var i in this.user.groups) {
                    var idx = this.user.groups[i]
                    if (idx) {
                        group.push(idx.id)
                    }
                }
                this.user.groups = group.join()
            }
            if (this.user.locations) {
                var location = []
                for (var i in this.user.locations) {
                    var idx = this.user.locations[i]
                    if (idx) {
                        location.push(idx.id)
                    }
                }
                this.user.locations = location.join()
            }

            var levelRole = this.optionsRoles[this.user.rol].level
            // if (levelRole < 3) {
            //     self.switchDashbord = false
            // }
            if (
                (this.emailChanged && this.statusUser && levelRole >= 3 && this.user.dashboard_access == 1) ||
                (this.changeToNewEmail && this.statusUser && levelRole >= 3 && this.user.dashboard_access == 1)
            ) {
                this.user.dashboard_access = 2
            }

            // validate if password is an string
            this.user.password = typeof this.user.password === 'string' ? this.user.password : ''
            this.user.email = this.emailForm
            var emailHasChanged = self.emailChanged
            if (self.switchDashboardApi == 0 && self.switchDashbord == true) {
                emailHasChanged = true
            } else if (!this.$route.params.id) {
                emailHasChanged = true
            }
            if (self.$route.params.id) {
                self.$store.dispatch('employee/editUser', self.user).then(function (response) {
                    self.$overlay.close()
                    if (self.user.dashboard_access && emailHasChanged && self.user.status != '-2' && self.user.status != '-3') {
                        var link = response.data.invitation_link
                        if (!link) {
                            self.$emit('back')
                            return true
                        }
                        self.$popup.invitationUser({
                            title: self.$t('user.form.invitation_dashboard_title'),
                            message: self.$t('user.form.invitation_dashboard_descrip', {
                                name: self.user.name + ' ' + self.user.surname,
                                email: self.user.email
                            }),
                            textSave: self.$t('user.form.send_invitation_button'),
                            inputVal: link,
                            textCancel: self.$t('user.form.in_other_moment'),
                            callSave: function () {
                                self.$overlay.loading()
                                self.sendInvitation({ resend: false, id: response.data.id })
                                    .then((response) => {
                                        self.$popup.close()
                                        self.$emit('back')
                                    })
                                    .catch((err) => {})
                            },
                            callCancel: function () {
                                self.$overlay.loading()
                                self.$popup.close()
                                self.$emit('back')
                                self.$snackbar.open({
                                    message: self.$t('snackbar.edit_success') + ' <b>' + self.user.name + '</b>',
                                    customClass: 'success',
                                    duration: 5000
                                })
                            }
                        })
                    } else {
                        self.$emit('back')
                        self.$snackbar.open({
                            message: self.$t('snackbar.edit_success') + ' <b>' + self.user.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    }
                })
            } else {
                self.$store.dispatch('employee/addUser', self.user).then(function (response) {
                    if (
                        response.data.invitation_link &&
                        self.user.dashboard_access &&
                        emailHasChanged &&
                        self.user.status != '-2' &&
                        self.user.status != '-3'
                    ) {
                        var link = response.data.invitation_link
                        self.$popup.invitationUser({
                            title: self.$t('user.form.invitation_dashboard_title'),
                            message: self.$t('user.form.invitation_dashboard_descrip', {
                                name: self.user.name + ' ' + self.user.surname,
                                email: self.user.email
                            }),
                            textSave: self.$t('user.form.send_invitation_button'),
                            inputVal: link,
                            textCancel: self.$t('user.form.in_other_moment'),
                            callSave: function () {
                                self.$overlay.loading()
                                self.sendInvitation({ resend: false, id: response.data.id })
                                    .then((response) => {
                                        self.$popup.close()
                                        self.$emit('back')
                                        self.$overlay.close()
                                        self.$snackbar.open({
                                            message: self.$t('snackbar.add_success') + ' <b>' + self.user.name + '</b>',
                                            customClass: 'success',
                                            duration: 5000
                                        })
                                    })
                                    .catch((err) => {})
                            },
                            callCancel: function () {
                                self.$popup.close()
                                self.$emit('back')
                                self.$overlay.close()
                                self.$snackbar.open({
                                    message: self.$t('snackbar.add_success') + ' <b>' + self.user.name + '</b>',
                                    customClass: 'success',
                                    duration: 5000
                                })
                            }
                        })
                    } else {
                        self.$emit('back')
                        self.$overlay.close()
                        self.$snackbar.open({
                            message: self.$t('snackbar.add_success') + ' <b>' + self.user.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    }
                })
            }
        },
        saveUser() {
            var self = this
            self.$overlay.loading()
            this.sendForm = true
            // var email = this.emptyAnswer.notSameEmail
            // set all values of errors to false
            this.emptyAnswer = _.mapValues(this.emptyAnswer, () => false)
            if (this.user.name === '') {
                this.sendForm = false
                this.emptyAnswer.name = true
            }

            var roleUserLevel = this.optionsRoles[this.user.rol] && this.optionsRoles[this.user.rol].level ? this.optionsRoles[this.user.rol].level : false
            if (
                (this.user.dashboard_access && this.emailForm == '' && roleUserLevel > 2) ||
                (this.user.dashboard_access && this.emailForm == null && roleUserLevel > 2)
            ) {
                this.sendForm = false
                this.emptyAnswer.email = true
            }

            if (roleUserLevel > 5) {
                this.user.groups = []
                this.user.locations = []
            }
            if (this.statusUser == false) {
                this.user.status = '-2'
            } else {
                this.user.status = '1'
            }
            if (this.validEmail) {
                this.sendForm = false
            }

            if (this.user.rol === '' || this.user.rol === null) {
                this.sendForm = false
                this.emptyAnswer.rol = true
            }
            if (this.hasPin) {
                if (this.user.pin == '' || this.user.pin == null) {
                    this.sendForm = false
                    this.emptyAnswer.pin = true
                }
            }
            if (this.newPin != '') {
                this.user.pin = this.newPin
                this.pinChanged = true
            }
            if ((this.newPin === '' && this.hasPin) || (this.newPin.length != 4 && this.hasPin)) {
                this.sendForm = false
                this.emptyAnswer.pin2 = true
            }
            if (this.newRole != '') {
                this.user.rol = this.newRole
            }
            if (this.globalAccess()) {
                if (this.user.groups.length == 0 && this.user.locations.length == 0) {
                    this.sendForm = false
                    this.emptyAnswer.locations = true
                }
            }
            if (this.sites == 'locations') {
                this.user.groups = []
            } else {
                this.user.locations = []
            }
            if (this.emptyAnswer.id) {
                this.sendForm = false
            }
            if (this.sendForm) {
                if (this.user.identification) {
                    var self = this
                    this.$store
                        .dispatch('employee/identificationUsed', {
                            identification: this.user.identification,
                            employee_id: this.user.id
                        })
                        .then(function (response) {
                            if (response) {
                                if (self.user.email || self.changeToNewEmail || self.emailChanged) {
                                    var params = { email: self.emailForm, id: self.user.id }
                                    if (self.emailForm != '') {
                                        self.$store.dispatch('employee/emailCheck', params).then(function (response) {
                                            if (response) {
                                                self.sendFormUser()
                                            } else {
                                                self.email_new = response
                                                self.$overlay.hide()
                                            }
                                        })
                                    } else {
                                        self.sendFormUser()
                                    }
                                } else {
                                    self.sendFormUser()
                                }
                            } else {
                                self.id_new = response
                                self.$overlay.hide()
                            }
                        })
                } else if (self.user.email || self.changeToNewEmail || self.emailChanged) {
                    var params = { email: self.emailForm, id: self.user.id }
                    if (self.emailForm != '') {
                        self.$store.dispatch('employee/emailCheck', params).then(function (response) {
                            if (response) {
                                self.sendFormUser()
                            } else {
                                self.email_new = response
                                self.$overlay.hide()
                            }
                        })
                    } else {
                        self.sendFormUser()
                    }
                } else {
                    this.sendFormUser()
                }
            } else {
                self.$overlay.hide()
                scrollError()
            }
        },

        deleteUser() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.user.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('employee/deleteUser', self.user.id).then(() => {
                self.$emit('back')
                self.$snackbar.open(snackbarData)
            })
        },

        showConfirmDeleteUserModal() {
            var self = this
            const modalMessage = self.$t('user.popup.delete_text_title') + self.user.name + "'?"

            this.$popup.delete({ message: modalMessage, callSave: this.deleteUser })
        },

        checkRoleIsValid(roleId) {
            let bRoleFound = false
            Object.values(this.allRoles).forEach((role) => {
                if (role.id == roleId) bRoleFound = true
            })

            return bRoleFound
        },

        sortedByLevel(roles) {
            return _.orderBy(roles, 'level')
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.importAll(require.context('../../../../public/img/avatar/', true, /\.svg$/))
    },
    watch: {
        emailForm(newValue) {
            if (newValue != this.user.email && this.$route.params.id) {
                this.emailChanged = true
            } else {
                this.emailChanged = false
            }
        }
    }
}
</script>

<style lang="scss">
#content.user-management {
    .btn-send {
        height: auto;
    }
    .disabled {
        opacity: 0.55;
        pointer-events: none;
    }

    .inline-delete {
        margin: 60px auto 30px auto;
        width: 100%;
        text-align: center;

        .text {
            @include background($size: 16px, $image: img('delete_error.svg'), $position: left center);
            @include font-size(ml);
            color: $color-error-500;
            text-align: center;
            display: inline-block;
            padding-left: 18px;
            cursor: pointer;
        }
    }

    .form-container .form {
        margin-top: -30px;
    }

    .form-container .form-title {
        margin-bottom: 16px;

        &.noMarginBottom {
            margin-top: 16px;
        }
    }

    .territory {
        margin-top: 30px;
        .info-box.access {
            width: 48%;
        }
    }

    .label-container {
        padding-bottom: 6px;

        .user-answer {
            .input-container {
                margin-right: 0px;
            }
            .input {
                height: 37px;
            }
            .id-input {
                margin-right: 0px;
            }
        }
        .name-section {
            margin-bottom: 8px;
            .input {
                height: 37px;
            }
        }

        &.spaced {
            margin-top: 30px;
        }
        .switch-input-form {
            height: 37px;
        }
    }

    .switch .active-switch {
        margin-top: 2px;
    }

    .switch .switch-click {
        margin: 3px 3px 3px 8px;
    }

    .description {
        &.w100 {
            width: 100%;
        }
    }
    .has-role {
        @include display-flex();

        button.btn.btn-edit {
            align-self: center;
            width: auto;
            min-width: fit-content;
            margin-left: 30px;
        }
    }
    .box-container {
        @include background($color: $color-neutral-200);
        @include display-flex();
        @include flex-wrap(wrap);
        width: 100%;
        height: auto;
        padding: 10px;
        border-radius: 4px;
        .input-role {
            width: 18px;
        }
        .input-role:checked {
            background-color: red;
        }
        .box-name {
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
            padding-right: 4px;
        }
        .default-name {
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
        }
        .have-description {
            @include display-flex();
            @include flex-direction(column);
            width: 100%;
        }
        .no-role {
            @include display-flex();
            @include flex-direction(column);
            width: 40%;
            height: auto;
            padding: 10px;
            cursor: pointer;
        }
        .box-info {
            @include display-flex();

            .select-role {
                cursor: pointer;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 2px solid $color-neutral-600;
                margin-right: 6px;
                &.selected {
                    @include background($image: img('checked_main.svg'));
                    border: none;
                }
            }
        }
        .box-description {
            @include font-size(xs);
            font-family: $text-medium;
            color: $color-neutral-600;
            padding-left: 24px;
        }
    }
    .column-description {
        @include display-flex();
        @include flex-direction(column);
        cursor: pointer;
        width: 100%;
    }
    .up {
        @include display-flex();

        .access {
            margin-right: 5px;
            margin-top: 2px;
        }
    }
    .access-container {
        @include background($color: $color-neutral-200);
        @include font-size(xs);
        color: $color-neutral-600;
        padding: 20px 20px;
        border-radius: 3px;
        .setPin {
            margin: 0px;
        }
        .bold-warning {
            @include font-size(xs);
            color: $color-warning-800;
            font-family: $text-bold;
        }

        #pin {
            -webkit-appearance: none;
        }
    }
    .sites {
        .v-input {
            @include flex-wrap(wrap);
        }
        .v-menu__content {
            position: inherit;
            width: 100%;
            max-width: unset !important;
        }
    }
}
</style>
