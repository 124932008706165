<template>
  <div class="avatar-container">
    <div class="avatar-selected">
      <img
        class="profile"
        v-for="image in images"
        :src="image.pathLong"
        :key="image.pathLong"
        v-if="
          image.pathShort.substring(2) ==
            avatarSelected.split('/')[avatarSelected.split('/').length - 1] ||
            image.pathShort.slice(2) == avatarSelected
        "
        alt="user-profile-photo"
      />
    </div>
    <div class="all-avatars">
      <div
        :style="{ backgroundImage: 'url(' + image.pathLong + ')' }"
        class="avatar-img"
        v-for="image in images"
        :key="image.pathLong"
        :class="{
          active: image.pathShort.slice(2) == avatarSelected,
          active:
            image.pathShort.substring(2) ==
            avatarSelected.split('/')[avatarSelected.split('/').length - 1]
        }"
        @click="setAvatar(image)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "avatarSelector",
  props: {
    avatarSelected: { type: String, default: "" }
  },
  data() {
    return {
      images: [],
      selected: ""
    };
  },
  computed: {
    isExpanded() {
      return this.$store.state["menu_tools_is_expanded"];
    }
  },
  methods: {
    importAll(r) {
      r.keys().forEach(key =>
        this.images.push({ pathLong: r(key), pathShort: key })
      );
    },
    setAvatar(image) {
      var img = image.pathShort.slice(2);
      this.selected = img;
      this.$emit('setImage', img)
    }
  },
  watch: {},
  mounted() {
    this.importAll(
      require.context("../../../../public/img/avatar/", true, /\.svg$/)
    );
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.avatar-container {
  @include display-flex();
  padding-bottom: 10px;
  width: 100%;

  .all-avatars {
    width: 80%;
  }
  .avatar-img {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: 2px 6px 4px 2px;
    opacity: 1;
    position: relative;
    display: inline-block;
    background-size: contain;

    &.active:after {
      content: "";
      width: 15px;
      height: 15px;
      display: block;
      @include background($image: img("checked_neutro_s70_2.svg"), $size: contain);
      z-index: $z-bot-content;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .profile {
    width: 50px;
    height: 50px;
  }
  .avatar-selected {
    align-self: center;
    padding-right: 5px;
  }
}
</style>
