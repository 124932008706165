<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE ID -->
            <div class="filter">
                <label>{{ $t('user.filters.id') }}</label>
                <input
                    type="text"
                    :class="{
                        filtered: currentFilters.identification && currentFilters.identification.length > 0,
                        changed: currentFilters.identification && currentFilters.identification != activeFilters.identification
                    }"
                    hide-details
                    class="input"
                    v-model="currentFilters.identification"
                    @keyup.enter="applyFilters()"
                />
            </div>
            <!-- TYPE NAME AND SURNAME -->
            <div class="filter">
                <label>{{ $t('user.filters.name') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && typeof currentFilters.name !== 'undefined' && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE Roles -->
            <div class="filter">
                <label>{{ $t('user.role') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.roles && typeof currentFilters.roles !== 'undefined' && Object.values(currentFilters.roles).length > 0,
                        changed: JSON.stringify(currentFilters.roles) != JSON.stringify(activeFilters.roles)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.roles"
                    :items="Object.values(optionsRoles)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.roles" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- TYPE SELECT SITE OR TERRITORIES -->
            <div class="filter">
                <label>{{ $t('user.filters.search_by') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.sites,
                        changed: JSON.stringify(currentFilters.sites) != JSON.stringify(activeFilters.sites)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.sites"
                    v-if="Object.values(optionsLocations)"
                    :items="Object.values(optionsLocations)"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    @change="refreshSearch()"
                ></v-select>

                <div class="sites" v-if="currentFilters.sites == 'locations'">
                    <label class="description">{{ $t('user.filters.available_sites') }}</label>
                    <v-autocomplete
                        :class="{
                            filtered: currentFilters.locations && typeof currentFilters.locations !== 'undefined' && Object.values(currentFilters.locations).length > 0,
                            changed: JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations)
                        }"
                        hide-details
                        class="select"
                        v-model="currentFilters.locations"
                        v-if="Object.values(optionsSites)"
                        :items="Object.values(optionsSites)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                        return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>
                    <Tags :tags="currentFilters.locations" :backImage="'location_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
                </div>
                <div class="custom" v-else-if="currentFilters.sites == 'territories'">
                    <label class="description">{{ $t('user.filters.available_territories') }}</label>
                    <v-autocomplete
                        :class="{
                            filtered: currentFilters.territories && typeof currentFilters.territories !== 'undefined' && Object.values(currentFilters.territories).length > 0,
                            changed: JSON.stringify(currentFilters.territories) != JSON.stringify(activeFilters.territories)
                        }"
                        hide-details
                        class="select"
                        v-model="currentFilters.territories"
                        v-if="Object.values(optionsTerritories)"
                        :items="Object.values(optionsTerritories)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                        return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>
                    <Tags :tags="currentFilters.territories" :nameTag="'name'" value="id" :removable="true"></Tags>
                </div>
            </div>
            <!-- Email -->
            <div class="filter">
                <label>{{ $t('user.filters.email') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.email && typeof currentFilters.email !== 'undefined' && Object.values(currentFilters.email).length > 0,
                        changed: currentFilters.email && currentFilters.email != activeFilters.email
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.email"
                    @keyup.enter="applyFilters()"
                />
            </div>
            <!-- status -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.state') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.status && typeof currentFilters.status !== 'undefined' && Object.values(currentFilters.status).length > 0,
                        changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status)
                    }"
                    hide-details
                    class="select"
                    v-if="Object.values(optionsStatus)"
                    v-model="currentFilters.status"
                    :items="Object.values(optionsStatus)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <FilterActions :showClearFiltersButton="showClearFiltersButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterUsers',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                status: [
                    { id: 1, name: i18n.t('tools.groups.status.active') },
                    { id: 2, name: i18n.t('user.filters.pending') }
                ],
                type_date: 'week',
                dashboard_access: 2
            },
            activeFilters: {},

            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true
        }
    },
    computed: {
        optionsRoles() {
            return this.$store.getters['employee/getFilterRoles']()
        },
        optionsSites() {
            return this.$store.getters['employee/getFilterSites']()
        },
        optionsTerritories() {
            return this.$store.getters['employee/getFilterGroups']()
        },
        optionsStatus() {
            return this.$store.getters['employee/getFilterStatus']()
        },
        optionsLocations() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                {
                    value: 'territories',
                    name: i18n.t('user.filters.territories')
                }
            ]
        },
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        refreshSearch() {
            delete this.currentFilters.locations
            delete this.currentFilters.territories
        },

        applyFilters() {
            this.$overlay.loading()
            var definitiveFilters = {}
            var territories = []

            if (this.currentFilters.identification) {
                definitiveFilters.identification = this.currentFilters.identification
            } else definitiveFilters.identification = ''

            if (this.currentFilters.name) {
                definitiveFilters.name = this.currentFilters.name
            } else definitiveFilters.name = ''

            if(this.currentFilters.email){
                definitiveFilters.email = this.currentFilters.email
            }else definitiveFilters.email = ""

            if (this.currentFilters.roles && Object.values(this.currentFilters.roles).length) {
                var filter = this.currentFilters.roles
                definitiveFilters.roles = []
                for (var index in filter) {
                    definitiveFilters.roles.push(filter[index].id)
                }
                definitiveFilters.roles = JSON.stringify(definitiveFilters.roles)
            } else definitiveFilters.roles = ''

            if (this.currentFilters.territories) {
                var filter = this.currentFilters.territories
                for (var index in filter) {
                    territories.push(filter[index].id)
                }
                definitiveFilters.territories = JSON.stringify(definitiveFilters.territories)
            } else {
                definitiveFilters.territories = ''
            }
            if (this.currentFilters.locations) {
                var filter = this.currentFilters.locations
                for (var index in filter) {
                    territories.push(filter[index].id)
                }
                definitiveFilters.territories = JSON.stringify(definitiveFilters.territories)
            } else {
                definitiveFilters.territories = ''
            }
            var havePendingInvitation = false
            // status
            if (this.currentFilters.status) {
                var filter = this.currentFilters.status
                definitiveFilters.status = []
                for (var index in filter) {
                    if (filter[index].id == 2) {
                        havePendingInvitation = true
                    }
                    definitiveFilters.status.push(filter[index].id)
                }
                definitiveFilters.status = definitiveFilters.status.toString()
            } else {
                definitiveFilters.status = ''
            }
            if (havePendingInvitation) {
                definitiveFilters.dashboard_access = 2
            } else {
                definitiveFilters.dashboard_access = 0
            }
            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }

            var self = this
            this.changesDetected = false

            var params = {
                identification: definitiveFilters.identification,
                names: definitiveFilters.name,
                email: definitiveFilters.email,
                roles: definitiveFilters.roles,
                location: [],
                groups: [],
                status: definitiveFilters.status,
                dashboard_access: definitiveFilters.dashboard_access
            }

            if (this.currentFilters.sites == 'locations') {
                params.location = territories
            } else {
                params.groups = territories
            }

            this.$store.commit('employee/setFiltersActive', this.currentFilters)

            var filterRoles = this.$store.getters['employee/getFilterRoles']()
            var filterSites = this.$store.getters['employee/getFilterSites']()
            var filterTerritories = this.$store.getters['employee/getFilterGroups']()

            if (Object.keys(filterRoles).length > 0 && Object.keys(filterSites).length > 0 && Object.keys(filterTerritories).length > 0) {
                self.$store.dispatch('employee/loadList', params).then(function () {
                    self.activeFilters = { ...self.currentFilters }
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
            } else {
                self.$store.dispatch('employee/loadFilters', {}).then(function () {
                    self.$store.dispatch('employee/loadList', params).then(function () {
                        self.activeFilters = { ...self.currentFilters }
                        self.$overlay.hide()
                        self.$emit('resetPageDatatable')
                    })
                })
            }
        },
        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.$store.commit('employee/setFiltersActive', this.currentFilters)

            this.changesDetected = false
            this.$store
                .dispatch('employee/loadList', {
                    status: '1,2',
                    dashboard_access: 2
                })
                .then(function () {
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        }
    },
    created() {},
    mounted() {
        var translated = this.$store.getters['employee/getFiltersActive']
        for (var name in translated.status) {
            var index = translated.status[name]
            if (index.name) {
                index.name = i18n.t(index.name)
            }
        }
        this.activeFilters = {
            ...translated
        }
        this.currentFilters = {
            ...translated
        }

        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
// Busca en assets :^)
</style>
